import { T } from '@dnx/core';
const tourT = (translationKey) => {
    return T(translationKey).split('\n').join('<br/><br/>');
};
export const getOverviewTourSteps = () => [
    {
        id: 'tab-overview',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TABS_OVERVIEW_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TABS_OVERVIEW_TEXT'),
        },
    },
    {
        id: 'filters',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_FILTERS_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_FILTERS_TEXT'),
        },
    },
    {
        id: 'conversations',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TABLE_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TABLE_TEXT'),
        },
    },
    {
        id: 'delete-conversation',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_DELETE_CONVERSATION_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_DELETE_CONVERSATION_TEXT'),
        },
    },
    {
        id: 'rescore',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_RESCORE_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_RESCORE_TEXT'),
        },
    },
    {
        id: 'table-column-settings',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TABLE_COLUMNS_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TABLE_COLUMNS_TEXT'),
        },
    },
];
export const getConversationTourSteps = () => [
    {
        id: 'tab-conversation',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TABS_CONVERSATION_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TABS_CONVERSATION_TEXT'),
        },
    },
    {
        id: 'statistics',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_STATISTICS_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_STATISTICS_TEXT'),
        },
    },
    {
        id: 'tags',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TAGS_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TAGS_TEXT'),
        },
    },
    {
        id: 'timeline',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TIMELINE_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TIMELINE_TEXT'),
        },
    },
    {
        id: 'speakers',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_SPEAKERS_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_SPEAKERS_TEXT'),
        },
    },
    {
        id: 'main-timeline',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_MAIN_TIMELINE_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_MAIN_TIMELINE_TEXT'),
        },
    },
    {
        id: 'transcript',
        popover: {
            title: tourT('MANGL_CONVERSATIONS_TOUR_TRANSCRIPT_TITLE'),
            description: tourT('MANGL_CONVERSATIONS_TOUR_TRANSCRIPT_TEXT'),
        },
    },
];
