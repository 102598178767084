import 'systemjs-webpack-interop/auto-public-path';

import {lifecycle} from './app.js';
import {routes} from './router';
import {tours} from '@dnx/core';
import Dashboard from './pages/overview.vue';

// singleSpa & mountParcel also in props
function createManifest() {
  const {name, version, description, dnx} = require('../package.json');
  const defaults = {
    color: '#ff0000',
    icon: 'statistics',
  };

  let manifest = {
    name: name,
    version,
    description,
    ...defaults,
    ...dnx,
  };
  return manifest;
}

tours.push(
  {
    translationKey: 'DASHBOARDING',
    tours: [
      {
        app: 'app-dashboards',
        id: 'mangl-dashboards',
        routeName: 'app-dashboards',
        translationKey: 'OVERVIEW',
        permissionId: 'b49d6212-46b0-44aa-b205-56758d84565b',
      },
    ],
  },
  {
    translationKey: 'CONVERSATIONS',
    tours: [
      {
        app: 'app-mangl-conversations',
        id: 'conversations-overview',
        routeName: 'app-mangl-conversations',
        translationKey: 'TOUR_COMPLETE',
        permissionId: 'b49d6212-46b0-44aa-b205-56758d84565b',
      },
      {
        app: 'app-mangl-conversations',
        id: 'conversation',
        routeName: 'app-mangl-conversations',
        translationKey: 'CONVERSATION',
        permissionId: 'b49d6212-46b0-44aa-b205-56758d84565b',
      },
    ],
  }
);

const manifest = createManifest();
export default manifest;

const bridge = {
  name: manifest.name,
  bootstrap: async props => {
    const {name, singleSpa} = props;
  },
  mount: async props => {
    const {interop} = props;
    interop?.register(bridge);
  },
  unmount: async props => {
    const {interop} = props;
    interop?.unregister(bridge);
  },
};

const core = {
  mount: async props => {
    const {name, mountParcel, customProps} = props;
    for (const [key, value] in customProps) manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel(bridge, {domElement: document.body, interop: Window.interop});

    Window._shellReady.then(() => {
      document.dispatchEvent(
        new CustomEvent('dnx:app-loaded', {
          detail: {
            appmodule: name,
            config: {
              component: Dashboard,
              routes,
            },
          },
        })
      );
    });
  },
};

export const bootstrap = [lifecycle.bootstrap];

export const mount = [core.mount, lifecycle.mount];
export const unmount = [lifecycle.unmount];
export const update = [core.update, lifecycle.update];
