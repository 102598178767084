export default function createEventEmitter() {
    const listeners = {};
    return {
        addEventListener(key, fn) {
            listeners[key] = (listeners[key] || []).concat(fn);
        },
        removeEventListener(key, fn) {
            listeners[key] = (listeners[key] || []).filter(f => f !== fn);
        },
        emit(key, data) {
            (listeners[key] || []).forEach(function (fn) {
                if (typeof fn === 'function') {
                    if (data === undefined) {
                        fn();
                    }
                    else {
                        fn(data);
                    }
                }
            });
        },
    };
}
