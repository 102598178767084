import { inject, provide, ref } from 'vue';
export const provideConversations = () => {
    const playingConversation = ref();
    const state = { playingConversation };
    provide('conversations-metadata', state);
    return state;
};
export const injectConversations = () => {
    const state = inject('conversations-metadata');
    if (!state) {
        throw 'No available data';
    }
    return state;
};
