var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class Recording {
    constructor() {
        this.paths = Object.freeze({
            upload: () => `api/mangl/conversations/create`,
            list: () => {
                return `api/mangl/conversations`;
            },
            getConversation: (idorpublicid) => `api/mangl/conversations/${encodeURIComponent(idorpublicid)}`,
            delete: (ids) => {
                const __query = new QueryStringBuilder().add("ids", ids);
                return `api/mangl/conversations${__query.toString()}`;
            },
            getFilterOptions: () => `api/mangl/conversations/filteroptions`,
            getConversationInfo: (idorpublicid) => `api/mangl/conversations/${encodeURIComponent(idorpublicid)}/info`,
            getAudio: (idorpublicid) => `api/mangl/conversations/${encodeURIComponent(idorpublicid)}.mp3`,
            getTags: (id) => `api/mangl/conversations/${encodeURIComponent(id)}/tags`,
            addTag: (id, tag) => `api/mangl/conversations/${encodeURIComponent(id)}/tags/${encodeURIComponent(tag)}`,
            removeTag: (id, tag) => `api/mangl/conversations/${encodeURIComponent(id)}/tags/${encodeURIComponent(tag)}`,
            getReportInfo: (id) => `api/mangl/conversations/${encodeURIComponent(id)}/report`,
            reportConversation: (id) => `api/mangl/conversations/${encodeURIComponent(id)}/report`,
            rescoreConversation: (id) => `api/mangl/conversations/${encodeURIComponent(id)}/rescore`,
            rescoreMultipleConversations: () => `api/mangl/conversations/rescore/multiple`,
            rescoreFromFilteredConversations: () => `api/mangl/conversations/rescore/query`,
            rescoreAllConversation: () => `api/mangl/conversations/rescore/all`,
        });
    }
    //upload omitted, only JSON bodies are supported
    /**
     * Recording.List "POST api/mangl/conversations"
     *
     * Obtain a paged list of conversations, filters may be optionally applied
     * @param {ConversationFilterModel} conversationfilter
     * @param {PaginationParameters} paginationparameters
     * @param {string} sort
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PagedList<SimpleConversationModel>>>} List of manglconversations resources
     */
    list(conversationfilter, pagenumber, pagesize, sort, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("PageNumber", pagenumber).add("PageSize", pagesize).add("sort", sort);
            let path = `api/mangl/conversations${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, conversationfilter);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetConversation "GET api/mangl/conversations/{idOrPublicId}"
     *
     * Get a single conversation
     * @param {string} idorpublicid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Conversation
     */
    getConversation(idorpublicid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${idorpublicid === undefined || idorpublicid === null ? "" : encodeURIComponent(idorpublicid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.Delete "DELETE api/mangl/conversations"
     *
     * Delete one or more conversations
     * @param {number[]} ids Ids of the conversations to delete
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Result indicating operation success
     */
    delete(ids, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("ids", ids);
            let path = `api/mangl/conversations${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetFilterOptions "GET api/mangl/conversations/filteroptions"
     *
     * Obtain a list of all available filter options
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ConversationFilterOptionsModel>>} Available filter options
     */
    getFilterOptions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/filteroptions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetConversationInfo "GET api/mangl/conversations/{idOrPublicId}/info"
     *
     * Obtain transcripts, etc. for a specific conversation
     * @param {string} idorpublicid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Conversation info
     */
    getConversationInfo(idorpublicid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${idorpublicid === undefined || idorpublicid === null ? "" : encodeURIComponent(idorpublicid)}/info`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetAudio "GET api/mangl/conversations/{idOrPublicId}.mp3"
     * @param {string} idorpublicid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getAudio(idorpublicid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${idorpublicid === undefined || idorpublicid === null ? "" : encodeURIComponent(idorpublicid)}.mp3`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetTags "GET api/mangl/conversations/{id}/tags"
     *
     * Get all tags for a conversation
     * @param {number} id Conversation id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<string[]>>} Tags
     */
    getTags(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/tags`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.AddTag "POST api/mangl/conversations/{id}/tags/{tag}"
     *
     * Add a tag to a conversation
     * @param {number} id Conversation id
     * @param {string} tag Tag name
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Result indicating operation success
     */
    addTag(id, tag, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/tags/${tag === undefined || tag === null ? "" : encodeURIComponent(tag)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.RemoveTag "DELETE api/mangl/conversations/{id}/tags/{tag}"
     *
     * Remove a tag from a conversation
     * @param {number} id Conversation id
     * @param {string} tag Tag name
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Result indicating operation success
     */
    removeTag(id, tag, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/tags/${tag === undefined || tag === null ? "" : encodeURIComponent(tag)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.GetReportInfo "GET api/mangl/conversations/{id}/report"
     *
     * Get info about the conversations reported status, including reasons.
     * @param {number} id Conversation id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ConversationReportModel>>} Tags
     */
    getReportInfo(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/report`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.ReportConversation "POST api/mangl/conversations/{id}/report"
     *
     * Get info about the conversations reported status, including reasons.
     * @param {number} id Conversation id
     * @param {ReportConversationModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>} Tags
     */
    reportConversation(id, model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/report`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.RescoreConversation "POST api/mangl/conversations/{id}/rescore"
     * @param {number} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    rescoreConversation(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/${id === undefined || id === null ? "" : encodeURIComponent(id)}/rescore`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.RescoreMultipleConversations "POST api/mangl/conversations/rescore/multiple"
     * @param {Guid[]} publicids
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    rescoreMultipleConversations(publicids, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/rescore/multiple`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, publicids);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.RescoreFromFilteredConversations "POST api/mangl/conversations/rescore/query"
     * @param {ConversationFilterModel} conversationfilter
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    rescoreFromFilteredConversations(conversationfilter, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/rescore/query`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, conversationfilter);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Recording.RescoreAllConversation "POST api/mangl/conversations/rescore/all"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    rescoreAllConversation(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/mangl/conversations/rescore/all`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const recording = new Recording();
export { recording as default, Recording };
