var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inject, provide, ref } from 'vue';
import overviewApi from './../../app/api-generated/recording';
import metadataApi from './../../app/api-generated/recordingMetadata';
export const provideConversationMetadata = () => {
    const conversationMetadata = ref();
    const allOptions = ref();
    const fetchSelectOptions = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield overviewApi.getFilterOptions();
        if (!response.success) {
            return {
                agents: [],
                callCodes: [],
                campaigns: [],
                tags: [],
                divisions: [],
            };
        }
        allOptions.value = response.value;
        return response.value;
    });
    const fetchMetadata = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield metadataApi.get(id);
        if (!response.success)
            return;
        conversationMetadata.value = response.value;
    });
    const updateMetadata = (id, metaData) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield metadataApi.put(id, metaData);
        return response;
    });
    const state = {
        conversationMetadata,
        fetchSelectOptions,
        fetchMetadata,
        updateMetadata,
        allOptions,
    };
    provide('conversations-metadata', state);
    return state;
};
export const injectConversationMetadata = () => {
    const state = inject('conversations-metadata');
    if (!state) {
        throw 'No available data';
    }
    return state;
};
