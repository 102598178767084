var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, inject, provide, ref, watch } from 'vue';
import { T, useDnxServerResponse, useNotifications, useTourContext } from '@dnx/core';
import { asyncComputed, useThrottleFn } from '@vueuse/core';
import conversationsApi from './../../app/api-generated/recording';
import { useRouter } from 'vue-router';
import { clone } from 'lodash';
import { useRouteQuery } from '@vueuse/router';
export const provideConversationDetailData = (id) => {
    const currentTime = ref(0);
    const router = useRouter();
    const notifications = useNotifications();
    const conversationInfo = useDnxServerResponse(conversationsApi.getConversationInfo, [id], {
        callback: response => {
            if (!response.success)
                navigateToOverview();
        },
    });
    const tour = useTourContext();
    conversationInfo.fetch();
    const conversation = useDnxServerResponse(conversationsApi.getConversation, [id], {
        callback: response => {
            if (!response.success)
                navigateToOverview();
        },
    });
    conversation.fetch();
    const query = useRouteQuery('opened-tab');
    const isFocused = computed(() => id == query.value);
    const isLoading = computed(() => {
        return conversation.isLoading.value || conversationInfo.isLoading.value;
    });
    const navigateToOverview = useThrottleFn(() => {
        notifications.errorNotification(T('SUBJECT_NOT_FOUND', { subject: T('CONVERSATION') }));
        // router.push('/conversations');
    }, 1000);
    const numberId = computed(() => {
        return conversation.value.value.id || undefined;
    });
    const reportData = asyncComputed(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!numberId.value)
            return { reasons: [] };
        const response = yield conversationsApi.getReportInfo(numberId.value);
        if (!response.success)
            return { reasons: [] };
        return response.value;
    }));
    const reasons = ref([]);
    const isReported = computed(() => reasons.value.some(x => x.value));
    watch(reportData, reportData => {
        reasons.value = clone(reportData.reasons);
    });
    const conversationData = asyncComputed(() => {
        if (!conversation.value.value || !conversationInfo.value.value)
            return undefined;
        return Object.assign(Object.assign({}, conversation.value.value), conversationInfo.value.value);
    });
    const transcriptGroupColors = ['--agent', '--client', '--client2'];
    const jumpToTime = ref();
    const textBubbles = computed(() => {
        if (!conversationData.value)
            return [];
        return conversationData.value.transcript.items.reduce((accumulator, value) => {
            if (!accumulator[value.intervalId]) {
                accumulator[value.intervalId] = {
                    start: value.start,
                    end: value.end,
                    words: [value],
                    speakerId: value.speakerId,
                    completeText: value.text + ' ',
                };
                return accumulator;
            }
            const currentValue = accumulator[value.intervalId];
            currentValue.end = value.end;
            currentValue.words.push(value);
            currentValue.completeText += value.text + ' ';
            accumulator[value.intervalId] = currentValue;
            return accumulator;
        }, []);
    });
    const speakers = computed(() => {
        if (!conversationData.value)
            return [];
        return conversationData.value.transcript.items.reduce((accumulator, word) => {
            if (!accumulator[word.speakerId]) {
                accumulator[word.speakerId] = {
                    words: [word],
                };
                return accumulator;
            }
            const currentValue = accumulator[word.speakerId];
            currentValue.words.push(word);
            accumulator[word.speakerId] = currentValue;
            return accumulator;
        }, []);
    });
    const registerElement = (...params) => {
        if (!isFocused.value)
            return;
        tour === null || tour === void 0 ? void 0 : tour.registerElement(...params);
    };
    const state = {
        id,
        numberId,
        conversationData,
        currentTime,
        transcriptGroupColors,
        textBubbles,
        speakers,
        jumpToTime,
        isLoading,
        reasons,
        isFocused,
        isReported,
        registerElement,
    };
    provide('conversations-detail', state);
    return state;
};
export const injectConversationDetailData = () => {
    const state = inject('conversations-detail');
    if (!state) {
        throw 'No available data';
    }
    return state;
};
