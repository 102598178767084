import { createWebHistory, createRouter } from 'vue-router';
export const routes = [
    {
        path: '/',
        name: 'conversations',
        component: () => import('./pages/overview.vue'),
        meta: { sidebar: true, translationKey: 'CONVERSATIONS' },
    },
    {
        path: '/:id',
        name: 'conversation',
        meta: { sidebar: true, translationKey: 'CONVERSATIONS' },
        redirect: to => ({ name: 'conversations', query: { 'opened-tab': to.params.id } }),
    },
];
const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
export default router;
