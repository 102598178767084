import * as XLSX from 'xlsx';
import createEventEmitter from './eventEmitter';
import useLoginToken from '../../app/utils/useLoginToken';
import { T } from '@dnx/core';
import { UploadProgressService } from './uploadProgressService';
import { throttle } from 'lodash';
function convertMetadataFileToObject(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            if (!event.target) {
                reject(new Error('FILE_INVALID'));
                return;
            }
            const contents = event.target.result;
            // Detect the file format based on the file extension
            const extension = file.name.split('.').pop().toLowerCase();
            if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
                // Read the workbook from the XLS/XLSX file
                const workbook = XLSX.read(contents, {
                    type: 'array',
                    raw: true,
                });
                // Assume the first sheet contains the data
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                // Convert the sheet to an array of objects
                const objects = XLSX.utils.sheet_to_json(sheet, {
                    header: 1,
                    raw: true,
                });
                if (!objects || objects.length === 0) {
                    reject(new Error(T('FILE_INVALID')));
                    return;
                }
                // Retrieve the headers from the first row (index 0)
                const headers = objects[0];
                // Initialize an empty array to store the resulting objects
                const results = [];
                // Iterate over the rows starting from the second row (index 1)
                for (let i = 1; i < objects.length; i++) {
                    const row = objects[i];
                    // Create a new object for each row
                    const obj = {};
                    // Flag to determine if the row has any valid data
                    let hasValidData = false;
                    // Iterate over the columns and map them to the corresponding header
                    for (let j = 0; j < headers.length; j++) {
                        const header = headers[j];
                        // console.log(header)
                        const value = row[j];
                        // console.log(value)
                        // Ensure both header and row value are valid before assigning
                        if (header && value) {
                            obj[header.toLowerCase()] = value;
                            hasValidData = true; // Mark that this row has valid data
                        }
                    }
                    // Only add the object if it contains valid data
                    if (hasValidData) {
                        results.push(obj);
                    }
                }
                const errors = new Set();
                let fatalError = undefined;
                const validatedResults = results.map((result) => {
                    if (fatalError)
                        return;
                    if (!result.filename) {
                        fatalError = `FileName ${T('IS_REQUIRED_FIELD')}`;
                        return;
                    }
                    if (result.calldatetime) {
                        const date = new Date(result.calldatetime);
                        if (isNaN(date.getTime())) {
                            result.calldatetime = undefined;
                        }
                        else {
                            result.calldatetime = date.toISOString();
                        }
                    }
                    if (result.result) {
                        if (result.result != '0' && result.result != '1') {
                            fatalError = T('FILE_INVALID');
                            return;
                        }
                    }
                    return result;
                });
                if (fatalError) {
                    reject(new Error(fatalError));
                }
                // Resolve the promise with the resulting JavaScript object
                resolve(validatedResults);
            }
            else {
                reject(new Error(T('FILE_INVALID')));
            }
        };
        reader.onerror = function () {
            reject(new Error(T('FILE_INVALID')));
        };
        if (file instanceof Blob) {
            reader.readAsArrayBuffer(file);
        }
        else {
            reject(new Error(T('FILE_INVALID')));
        }
    });
}
export const uploadAudioFiles = (files, divisionId) => {
    const availableKeys = [
        'recordingName',
        'agentName',
        'agentId',
        'campaignName',
        'campaignId',
        'callCodeName',
        'callCode',
        'callDateTime',
        'result',
    ];
    const uploadTask = UploadProgressService.createTask();
    const queue = Object.assign([], {
        /** Start processing our queue */
        start() {
            const next = () => {
                const current = queue[0];
                if (!current)
                    return Promise.resolve();
                return current.execute().then(() => {
                    // only remove post completion, allows inclusion within cancel
                    queue.shift();
                    return next();
                });
            };
            return next();
        },
        /** Cancel any not yet completed items */
        cancel() {
            // cancel the individual item
            for (let item = queue.pop(); item; item = queue.pop()) {
                item.cancel();
            }
        },
    });
    const filesProgressInfo = files.map(file => {
        var _a;
        const { token } = useLoginToken();
        var xhr = new XMLHttpRequest();
        const eventEmitter = createEventEmitter();
        const info = {
            progress: 0,
            abort: () => eventEmitter.emit('abort'),
            stopped: false,
            success: false,
            eventEmitter,
        };
        const data = new FormData();
        data.append('Recording', file.audioFile, file.audioFile.name);
        if (divisionId) {
            data.append('metadata.divisionId', divisionId);
        }
        data.append('metadata.recordingName', ((_a = file.metadata) === null || _a === void 0 ? void 0 : _a.filename) || file.audioFile.name);
        file.metadata &&
            Object.entries(file.metadata).forEach(entry => {
                if (entry[0] == 'filename')
                    return;
                if (!entry[1] && `${entry[1]}` !== '0')
                    return; // entry[1] can be the number 0 for the result column
                const key = availableKeys.find(x => x.toLowerCase() == entry[0]);
                if (!key)
                    return;
                if (key == 'result') {
                    data.append('metadata.isCallSuccessful', entry[1].toString() == '1' ? true : false);
                }
                else {
                    data.append(`metadata.${key}`, entry[1].toString());
                }
            });
        xhr.withCredentials = true;
        xhr.upload.onprogress = throttle(function (event) {
            const percentage = (event.loaded / event.total) * 100;
            info.progress = percentage;
            uploadTask.reportProgress(file.audioFile, percentage);
            eventEmitter.emit('progress', percentage);
        }, 2500);
        xhr.addEventListener('load', () => {
            info.stopped = true;
            const success = xhr.status == 201 ? true : false;
            info.success = success;
            uploadTask.reportCompletion(file.audioFile);
            eventEmitter.emit('onUploaded', success);
        });
        xhr.addEventListener('abort', () => {
            info.stopped = true;
            eventEmitter.emit('abort');
        });
        uploadTask.reportSchedule(file.audioFile, xhr);
        // should move this to an uploadstore, allow cancelling individual items (= remove from queue)
        queue.push({
            execute: () => new Promise(resolve => {
                // xhr.onabort
                xhr.open('POST', '/api/mangl/conversations/create', true);
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                xhr.send(data);
                uploadTask.reportStart(file.audioFile);
                eventEmitter.addEventListener('onUploaded', () => resolve(undefined));
                eventEmitter.addEventListener('abort', () => resolve(undefined));
            }),
            cancel() {
                try {
                    // can this fail?, should test..
                    xhr.abort();
                }
                catch (e) { }
            },
        });
        return info;
    });
    // prevent refresh, leave, etc. without confirming when we have running uploads
    const warnOnPageLeave = (event) => {
        event.preventDefault();
        event.returnValue = '';
    };
    /**
      Central location for handling uploads, can start with an XMLHTTPRequest backend and later switch to an Uppy backend
     
      UploadService
        enqueue({
          form: ...
          endpoint: ...
        })
     
     */
    window.addEventListener('beforeunload', warnOnPageLeave);
    const task = Window.runtime.user
        .addTask(T('MANGL_CONVERSATIONS_UPLOADS_PENDING_TASK_LABEL'))
        .addCleanup(() => queue.cancel());
    // process files one by one
    queue.start().finally(() => {
        var _a;
        window.removeEventListener('beforeunload', warnOnPageLeave);
        (_a = Window.runtime.user) === null || _a === void 0 ? void 0 : _a.resolveTask(task); // <-- can be fired post-logout, nulluser/interact via other centralized way?
    });
    return filesProgressInfo;
};
export default convertMetadataFileToObject;
