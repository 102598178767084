import { T, useProgressService } from '@dnx/core';
import { nextTick } from 'vue';
import { useNotifications, createUploadProgressPopup } from '@dnx/core';
/** Class communicating progress for one single upload */
class UploadTask {
    get totalFiles() {
        return this._totalFiles;
    }
    constructor(group) {
        this._totalFiles = 0;
        // use a weakmap to allow the same file to be uploaded multiple times
        // not a realistic scenario - but no reason to enforce arbitrary limitations
        this._fileIds = new WeakMap();
        this._progressService = useProgressService();
        this._group = group;
        this.id = window.crypto.randomUUID();
    }
    /** Report a file is scheduled to be uploaded */
    reportSchedule(file, xhr) {
        let totalFiles = ++this._totalFiles;
        this._progressService.push({
            group: this._group,
            title: T('MANGL_CONVERSATIONS_PROGRESS_UPLOAD_TITLE'),
            caption: T(totalFiles > 1
                ? 'MANGL_CONVERSATIONS_PROGRESS_UPLOAD_FILES_CAPTION'
                : 'MANGL_CONVERSATIONS_PROGRESS_UPLOAD_FILE_CAPTION', { totalFiles }),
            task: this.id,
            id: this._fileId(file),
            detail: {
                name: file.name,
                size: file.size,
            },
        });
        this._progressService.subscribe(this._fileId(file), (subTaskStatus) => {
            const { successNotification } = useNotifications();
            if (subTaskStatus == 'cancelled') {
                xhr.abort();
                this._progressService.cancel(this._fileId(file));
            }
        });
        UploadProgressService.openPopup(this._group);
    }
    /** Report an upload has started */
    reportStart(file) {
        this._progressService.start(this._fileId(file));
    }
    /** Report an upload has been canceled */
    reportCancellation(file) {
        this._progressService.cancel(this._fileId(file));
    }
    /** Report an upload has completed */
    reportCompletion(file) {
        this._progressService.complete(this._fileId(file));
    }
    /** Report progress for an upload */
    reportProgress(file, progress) {
        let totalFiles = ++this._totalFiles;
        this._progressService.update({
            id: this._fileId(file),
            statusText: T(totalFiles > 1
                ? 'MANGL_CONVERSATIONS_PROGRESS_UPLOAD_FILES_CAPTION'
                : 'MANGL_CONVERSATIONS_PROGRESS_UPLOAD_FILE_CAPTION', { totalFiles }),
            progress,
        });
    }
    _fileId(file) {
        if (!this._fileIds.has(file))
            this._fileIds.set(file, window.crypto.randomUUID());
        return this._fileIds.get(file);
    }
}
/** Class for interacting with progress service in the context of MANGL file uploads */
export class UploadProgressService {
    static createTask() {
        return new UploadTask(UploadProgressService.group);
    }
    static getProgress() {
        const progressService = useProgressService();
        return progressService.groups[UploadProgressService.group];
    }
    static cancel(taskId) {
        const progressService = useProgressService();
        progressService.cancel(taskId);
    }
    static remove(taskId) {
        const progressService = useProgressService();
        progressService.remove(taskId);
    }
    static openPopup(groupName) {
        nextTick(() => {
            createUploadProgressPopup(groupName, document.querySelector('.indicators-wrapper'), 'right', 'end');
        });
    }
    static closePopup() {
        UploadProgressService.getProgress().isOpen = false;
    }
}
UploadProgressService.group = 'app-mangl-conversations';
